@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.total-sum {
  display: flex;
  flex-direction: column;
  width: 100%;
}
