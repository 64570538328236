@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.card-checkout-info {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 652px;
  width: 100%;
  padding: 18px 14px;
  border-radius: var(--cornerradius12);
  box-shadow: inset 0 0 0 2px var(--cst-cards-checkout-info-outline);
  background: var(--cst-cards-checkout-info-background);

  .header,
  .footer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .header {
    .header-info {
      display: flex;
      align-items: center;
      gap: 10px;

      .text {
        @include typography-s;
        color: var(--cst-cards-checkout-info-text);

        @include min-1440-break {
          @include typography-m;
        }
      }

      .amount {
        margin-right: 10px;
        white-space: nowrap;
        @include typography-base;
        color: var(--cst-cards-checkout-info-amount);

        @include min-1440-break {
          @include typography-m;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;

    .content-item {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      .value-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

        .value {
          @include typography-s;
          @include weight-semi-bold;

          &-positive {
            color: var(--cst-cards-checkout-info-discount);
          }

          &-negative {
            color: var(--cst-cards-checkout-info-price-increase);
          }
          &-neutral {
            color: var(--cst-cards-checkout-info-detatils);
          }

          @include min-1440-break {
            @include typography-base;
          }
        }
        .old-value {
          color: #9e9d9e;
          text-decoration: line-through;
          @include typography-s;
          @include weight-medium;
        }
      }

      .title-block {
        display: flex;
        align-items: center;
        gap: 8px;

        .decoration {
          color: var(--cst-cards-checkout-info-particle);
        }

        .title {
          color: var(--cst-cards-checkout-info-item);

          @include typography-s;
          @include weight-medium;

          @include min-1440-break {
            @include typography-base;
          }
        }
      }
    }
  }

  .footer {
    .amount {
      @include typography-base;
      @include weight-semi-bold;

      @include min-1440-break {
        @include typography-m;
      }
    }

    .footer-info {
      display: flex;
      align-items: center;
      gap: 10px;

      .amount-text {
        @include typography-s;
        @include weight-medium;

        @include min-1440-break {
          @include typography-m;
        }
      }
    }
  }
}

.switch {
  @include fixedSize(34px, 19px);
  border-radius: var(--cornerradius9);
  padding: 2px;

  @include min-1440-break {
    @include fixedSize(46px, 24px);
  }
}
